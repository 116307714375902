<template>
  <div v-if="getProgram.length">
    <div class="program__head d-flex align-self-center">
      <svg width="38" height="40" viewBox="0 0 38 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M32 34H6L0 40V6C0 2.7 2.7 0 6 0H32C35.3 0 38 2.7 38 6V28C38 31.3 35.3 34 32 34Z" fill="#2196F3"/>
        <path d="M17 14H21V25H17V14Z" fill="white"/>
        <path d="M19 11C20.1046 11 21 10.1046 21 9C21 7.89543 20.1046 7 19 7C17.8954 7 17 7.89543 17 9C17 10.1046 17.8954 11 19 11Z" fill="white"/>
      </svg>
      <span>Информация о секциях</span>
    </div>
    <div class="program__content">
      <slick :options="slickOptions">
        <div class="program__slide" v-for="(slide, index) in getProgram" :key="index">
          <div class="program__section">
            {{slide.programSection.name}}
            <span style="text-transform:uppercase;">{{slide.programSection.theme}}</span>
          </div>
          <div class="program__desc">Тема выступления:</div>
          <div class="program__text" v-html="slide.name"></div>
          <div class="program__speakers" v-if="slide.Speaker.length">
            <div class="program__speaker" v-for="speaker in slide.Speaker" :key="speaker.id">
              <div class="speaker__name">{{speaker.name}}</div>
              <div class="speaker__job">
                {{speaker.job}}
              </div>
            </div>
          </div>
          <div class=" text-end pt-3 d-flex align-items-center justify-content-end">
            <div class="program__link" @click="goTo(slide.section_id)">
              <span>Перейти</span>
            </div>
          </div>
        </div>
      </slick>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import _ from 'lodash';
import Slick from "vue-slick";

export default {
  name: "RaspSlider",
  components: {
    Slick
  },
  data() {
    return {
      now: null,
      interval: null,
      slickOptions: {
        slidesToShow: 1,
        infinite: true,
        arrows: false,
        dots: true,
        adaptiveHeight: true,
        autoplay: true,
        timeout: 5000,
      },
    }
  },
  computed: {
    getProgram() {
      const programs = this.$store.getters.getProgramNoFilter
      return _.sortBy(_.filter(programs, program => {
        let start = moment(program.timeStart, 'HH:mm').format('X')
        let end = moment(program.timeEnd, 'HH:mm').format('X')
        return  this.now >= start && this.now < end
      }), ['section_id'])
    }
  },
  methods: {
    goTo(section) {
      this.$router.push({
        name: 'Telecast',
        query: {
          section: section
        }
      })
    }
  },
  mounted() {
    this.now = moment.utc().format('X')
    this.interval = setInterval(() => {
      this.now = moment.utc().format('X')
    }, 1000 * 20)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style scoped lang="scss">
  .speaker {
    &__name {
      font-weight: 500;
      font-size: rem(14);
      color: #1D2130;
    }
    &__job {
      font-weight: 400;
      font-size: rem(14);
      color: #1D2130;
      opacity: 0.7;
      padding-top: rem(3);
    }
  }
  .program {
    &__content {
      padding-top: rem(20);
      //box-shadow: 0px 0px 10px rgba(69, 171, 219, 0.47);

    }
    &__slide {
      background: #FFFFFF;
      opacity: 0.95;
      border-radius: 10px;
      padding: rem(20);
      box-sizing: border-box;
    }
    &__desc {
      font-weight: 500;
      font-size: rem(16);
      padding-top: rem(10);
      color: #282938;

    }
    &__section {
      font-style: normal;
      font-weight: 700;
      font-size: rem(21);
      color: #00476D;
    }
    &__head {
      svg {
        vertical-align: top;
      }
      span {
        vertical-align: middle;
        position: relative;
        padding-left: rem(10);
        font-weight: 400;
        font-size: rem(18);
        top: 3px;
        color: #282B2E;
      }
    }
    &__speakers {
      padding-top: rem(10);
    }
    &__speaker {
      padding-bottom: rem(6);
      &:last-child {
        padding-bottom: 0;
      }
    }
    &__link {
      cursor: pointer;
      span {
        font-weight: 200;
        font-size: rem(12);
        color: #282938;
        padding-right: rem(12);
      }
    }
    &__text {
      padding-top: rem(5);
      &::v-deep {
        * {
          font-size: rem(14);
          font-weight: 300;
        }
        p {
          margin-bottom: 0;
        }
      }
    }
  }
  .program__content {
    &::v-deep {
      .slick-dots {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        list-style-type: none;
        padding-top: rem(10);
        li {
          padding: 0 rem(2);
          button {
            min-height: 14px;
            min-width: 14px;
            border: 1px solid #0079C1;
            box-sizing: border-box;
            display: block;
            border-radius: 200px;

            background: transparent;
            font-size: 0;
          }
          &.slick-active {
            button {
              background-color: #0079C1;
            }
          }
        }
      }
    }
  }
</style>
